// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-cara/src/templates/cara.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-cara-src-templates-cara-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-orig-index-tsx": () => import("./../../../src/pages/origIndex.tsx" /* webpackChunkName: "component---src-pages-orig-index-tsx" */),
  "component---src-templates-basepages-tsx": () => import("./../../../src/templates/basepages.tsx" /* webpackChunkName: "component---src-templates-basepages-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-portfolio-list-tsx": () => import("./../../../src/templates/portfolio-list.tsx" /* webpackChunkName: "component---src-templates-portfolio-list-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("./../../../src/templates/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */),
  "component---src-templates-sections-tsx": () => import("./../../../src/templates/sections.tsx" /* webpackChunkName: "component---src-templates-sections-tsx" */)
}

